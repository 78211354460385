<template>
    <b-row>
        <b-col cols="12">
            <!-- 1 -->
            <b-card no-body class="mb-4">
                <h3 class="p-3">Bank List</h3>
                <!-- Header -->
                <b-card-body class="p-3 d-flex justify-content-between">
                    <b-form-group class="mb-2 w-25">
                        <b-input-group>
                            <b-form-input
                                id="filter-input"
                                v-model="filter"
                                type="search"
                                placeholder="Type to Search"
                            ></b-form-input>

                            <b-input-group-append>
                                <b-button variant="danger" :disabled="!filter" @click="filter = ''">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                    <div class="d-flex align-items-center">
                        <b-button variant="primary" class="mr-2" v-b-modal.modal-bank @click="titleModal = 'CREATE'"
                            >Add</b-button
                        >
                    </div>
                </b-card-body>
                <!-- End Section -->

                <!-- Data Table -->
                <b-table
                    style="min-height: 250px"
                    show-empty
                    responsive
                    small
                    class="mb-0"
                    head-variant="light"
                    :items="banks"
                    :filter="filter"
                    :fields="fields"
                    hover
                >
                    <template #cell(id)="data"> #{{ data.item.id }} </template>
                    <template #cell(isActive)="data">
                        <b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
                        <b-badge variant="danger" v-else>Inactive</b-badge>
                    </template>
                    <template #cell(action)="data">
                        <b-dropdown variant="primary" id="dropdown-1" text="Actions" class="">
                            <b-dropdown-item v-b-modal.modal-bank @click="editBank(data.item)">Edit</b-dropdown-item>
                            <!-- <b-dropdown-item v-b-modal.modal-remove>Delete</b-dropdown-item> -->
                        </b-dropdown>
                    </template>
                </b-table>
                <!-- End Section -->

                <!-- Pagination -->
                <div class="p-3 d-flex align-items-center">
                    <b-form-group
                        label="Per page"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="9"
                        label-size="sm"
                        label-class="fw-medium"
                        class="mb-0"
                    >
                        <b-form-select
                            @change="getBanks"
                            id="per-page-select"
                            v-model="perPage"
                            :options="pageOptions"
                            size="sm"
                        ></b-form-select>
                    </b-form-group>
                    <div class="ml-auto">
                        <b-pagination
                            @input="getBanks"
                            v-model="currentPage"
                            :total-rows="totalBanks"
                            :per-page="perPage"
                            align="fill"
                            class="my-0"
                        ></b-pagination>
                    </div>
                </div>
                <!-- End Section -->
            </b-card>
        </b-col>

        <!-- Modal -->
        <b-modal id="modal-bank" hide-footer centered :title="titleModal" @close="closeModal">
            <b-form @submit.prevent="createBank">
                <b-form-group label="Description" label-class="fw-medium mb-1" label-for="name">
                    <b-form-input
                        id="name"
                        type="text"
                        placeholder="Bank name here"
                        required
                        v-model="name"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>

                <b-button block :disabled="loadingBank" variant="primary" type="submit">
                    <b-spinner small v-if="loadingBank"></b-spinner>
                    <span v-else>Save</span>
                </b-button>
            </b-form>
        </b-modal>

        <b-modal id="modal-remove" size="sm" hide-footer centered title="Delete Bank">
            <b-card-text> Are you sure to want to remove this bank? </b-card-text>
            <div class="d-flex justify-content-end">
                <!-- <b-button variant="danger" class="mr-2" @click="removeRoles">Yes</b-button> -->
                <b-button variant="danger" class="mr-2">Yes</b-button>
                <!-- <b-button variant="secondary" @click="$bvModal.hide('modal-remove')">No</b-button> -->
                <b-button variant="secondary">No</b-button>
            </div>
        </b-modal>
        <!-- End Section -->
    </b-row>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'banks',

    data: () => ({
        page: {
            title: 'banks',
        },
        filter: null,
        fields: [
            {
                key: 'id',
                label: 'No',
                sortable: true,
            },
            {
                key: 'name',
                label: 'Description',
                sortable: true,
            },
            {
                key: 'action',
                label: 'Actions',
                sortable: false,
            },
        ],

        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],
        titleModal: 'Create Bank',
        loadingBank: false,
        name: '',
        bankSelected: {},
    }),
    components: {},
    methods: {
        clearValues() {
            this.name = '';
            this.bankSelected = {};
        },
        closeModal() {
            this.isEditing = false;
            this.clearValues();
            this.titleModal = 'Create Bank';
        },
        async editBank(bank) {
            const bankData = await this.$store.dispatch('general/banks/get_bank', bank.id);
            this.isEditing = true;
            this.bankSelected = bankData;
            this.name = bankData.name;
            this.titleModal = 'EDIT';
        },
        async getData() {
            await this.getBanks();
            await this.$store.dispatch('authorizations/get_allAuthorizations');
        },
        async getBanks() {
            let data = {
                skip: this.currentPage,
                take: this.perPage,
            };
            await this.$store.dispatch('general/banks/get_allBanks', data);
        },
        async createBank() {
            this.loadingBank = true;
            let data = {
                name: this.name,
            };

            if (this.isEditing) {
                (data.id = this.bankSelected.id), await this.$store.dispatch('general/banks/update_bank', data);
            } else {
                await this.$store.dispatch('general/banks/create_bank', data);
            }

            this.clearValues();
            await this.getBanks();
            this.$bvModal.hide('modal-bank');
            this.loadingBank = false;
        },
    },
    computed: {
        ...mapGetters({
            banks: 'general/banks/getBanks',
            totalBanks: 'general/banks/getTotalBanks',
            authorizations: 'authorizations/getAuthorizations',
        }),
    },
    mounted() {},
    created() {
        this.getData();
    },
};
</script>
